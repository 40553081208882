// TPDirect sdk version
const TAPPAY_SDK_VERSION = '5.15.1';
// tappay sdk url
const TAPPAY_SDK_URL = `https://js.tappaysdk.com/sdk/tpdirect/v${TAPPAY_SDK_VERSION}`;
// tappay app id
const TAPPAY_APP_ID = process.env.GATSBY_TAPPAY_APP_ID;
// tappay app key
const TAPPAY_APP_KEY = process.env.GATSBY_TAPPAY_APP_KEY;
// tappay server type
const TAPPAY_SERVER_TYPE = process.env.GATSBY_TAPPAY_SERVER_TYPE;

// initailize tap pay
const initTPDirect = () => {
  return new Promise((resolve, reject) => {
    if (window.TPDirect) {
      console.log('TPDirect already exists');
      resolve(window.TPDirect);
    } else {
      console.log('TPDirect not exists');
      const script = document.createElement('script');
      script.src = TAPPAY_SDK_URL;
      script.onload = () => {
        window.TPDirect.setupSDK(
          TAPPAY_APP_ID,
          TAPPAY_APP_KEY,
          TAPPAY_SERVER_TYPE
        );
        resolve(window.TPDirect);
      };
      document.body.appendChild(script);
    }
  });
};

const getDeviceId = () => {
  const TPDirect = window.TPDirect;
  return TPDirect.getDeviceId();
};

// 實作TPDirect使用tapPayRedirect
const tapPayRedirect = (payment_url) => {
  const TPDirect = window.TPDirect;
  TPDirect.redirect(payment_url);
};

export { initTPDirect, tapPayRedirect, getDeviceId };
